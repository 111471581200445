import { gql } from '@apollo/client';

export const GET_INTEGRATIONS_LIST = gql`
  query getConfiguredIntegrations(
    $where: IntegrationConfigsWhere!
    $filter: IntegrationsFilter
  ) {
    getConfiguredIntegrations(where: $where, filter: $filter) {
      data {
        id
        name
        key
        config
        logo
        helpLink
        description
        isActive
        isConfigured
        action
        integrationConfigs {
          id
          configs
        }
      }
    }
  }
`;

export const GET_INTEGRATIONS_EVENTS_LIST = gql`
  query events($data: EventsFilter) {
    events(data: $data) {
      count
      data {
        name
        key
        jsonValue
        id
        textValue
      }
    }
  }
`;

export const GET_USER_INTEGRATION_CONFIG = gql`
  query IntegrationConfig($where: IntegrationConfigWhere!) {
    integrationConfig(where: $where) {
      data {
        id
        configs
      }
    }
  }
`;

export const GET_USER_INTEGRATION_CONFIG_EVENTS = gql`
  query IntegrationEvents(
    $where: IntegrationEventsInput!
    $filter: IntegrationEventFilter
  ) {
    integrationEvents(where: $where, filter: $filter) {
      count
      data {
        createdBy
        event {
          jsonValue
          id
          createdAt
          key
          name
          textValue
        }
        eventKey
        id
        integrationConfigId
        updatedBy
      }
    }
  }
`;

export const GET_INTEGRATION_AUTH_URL = gql`
  query GetIntegrationAuthUrl($platform: IntegrationPlatform!, $teamId: ID!) {
    getIntegrationAuthUrl(platform: $platform, teamId: $teamId) {
      url
    }
  }
`;
