import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  CREATE_INTEGRATION_CONFIG,
  CREATE_INTEGRATION_EVENT,
  UPDATE_INTEGRATION
} from '../graphql/Mutations';
import {
  GET_INTEGRATIONS_EVENTS_LIST,
  GET_USER_INTEGRATION_CONFIG,
  GET_USER_INTEGRATION_CONFIG_EVENTS
} from '../graphql/Queries';

const { Option } = Select;
function AppConnectPopup({
  appConnectPopupData,
  handleClose,
  teamId,
  getIntegrationList
}) {
  const [isButtonLoader, setIsButtonLoader] = useState(false);
  const [step, setStep] = useState(null);
  const [form] = Form?.useForm();
  const [eventList, setEventList] = useState([]);
  const [eventInputList, setEventInputList] = useState([]);
  const [configId, setConfigId] = useState(null);

  const [getIntegrationEvents] = useLazyQuery(GET_INTEGRATIONS_EVENTS_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setEventList(res?.events?.data);
      setIsButtonLoader(false);
    },
    onError() {
      setIsButtonLoader(false);
    }
  });

  const [getUserIntegrationConfig] = useLazyQuery(GET_USER_INTEGRATION_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const values = {};
      appConnectPopupData?.popupData?.config?.forEach((item) => {
        const getValue = res?.integrationConfig?.data?.configs?.[item?.key];
        values[`${item.key}`] = getValue;
      });
      form?.setFieldsValue(values);
      setConfigId(appConnectPopupData?.popupData?.integrationConfigs?.[0].id);
      setIsButtonLoader(false);
    },
    onError() {
      setIsButtonLoader(false);
    }
  });

  const [getUserIntegrationConfigEvents] = useLazyQuery(
    GET_USER_INTEGRATION_CONFIG_EVENTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const eventsArray = res?.integrationEvents?.data?.map(
          (item) => item?.eventKey
        );
        setEventInputList(eventsArray);
        setIsButtonLoader(false);
      },
      onError() {
        setIsButtonLoader(false);
      }
    }
  );

  useEffect(() => {
    const stepDecider =
      appConnectPopupData?.popupData?.action === 'REDIRECTION' ? 2 : 1;
    if (stepDecider === 2) {
      getIntegrationEvents();
    }
    setStep(stepDecider);
    if (appConnectPopupData?.isEdit) {
      getUserIntegrationConfig({
        variables: {
          where: {
            integrationConfigId:
              appConnectPopupData?.popupData?.integrationConfigs?.[0].id,
            teamId: teamId
          }
        }
      });
      getUserIntegrationConfigEvents({
        variables: {
          where: {
            teamId: teamId,
            integrationConfigId:
              appConnectPopupData?.popupData?.integrationConfigs?.[0].id
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appConnectPopupData?.isEdit]);

  const [createIntegrationConfig] = useMutation(CREATE_INTEGRATION_CONFIG, {
    onCompleted: (res) => {
      getIntegrationEvents();
      setStep(2);
      setConfigId(res?.createIntegrationConfig?.data?.id);
      setIsButtonLoader(false);
    },
    onError() {
      setIsButtonLoader(false);
    }
  });

  const [updateIntegrationConfig] = useMutation(UPDATE_INTEGRATION, {
    onCompleted() {
      getIntegrationEvents();
      setStep(2);
      setIsButtonLoader(false);
    },
    onError() {
      setIsButtonLoader(false);
    }
  });

  const [submitEventsList] = useMutation(CREATE_INTEGRATION_EVENT, {
    onCompleted() {
      handleClose();
      setStep(1);
      getIntegrationList();
      setIsButtonLoader(false);
    },
    onError() {
      setIsButtonLoader(false);
    }
  });

  const handleIntegrationForm = async (values) => {
    setIsButtonLoader(true);
    if (appConnectPopupData?.isEdit) {
      updateIntegrationConfig({
        variables: {
          data: {
            configs: values
          },
          where: {
            integrationConfigId:
              appConnectPopupData?.popupData?.integrationConfigs?.[0].id
          }
        }
      });
    } else {
      createIntegrationConfig({
        variables: {
          data: {
            integrationKey: appConnectPopupData?.popupData?.key,
            configs: values,
            teamId: teamId
          }
        }
      });
    }
  };

  const handleEventsChange = (e, eventKey) => {
    const getEventsInput = [...eventInputList];
    if (e) {
      getEventsInput?.push(eventKey);
    } else {
      getEventsInput.splice(
        getEventsInput?.findIndex((item) => item === eventKey),
        1
      );
    }
    setEventInputList([...getEventsInput]);
  };

  const handleCommonSave = () => {
    if (step === 1) {
      form?.submit();
    } else {
      setIsButtonLoader(true);
      submitEventsList({
        variables: {
          data: {
            eventKeys: eventInputList,
            integrationConfigId: configId
          }
        }
      });
    }
  };

  const handleSelectAll = (e) => {
    if (e?.target?.checked) {
      setEventInputList(eventList?.map((item) => item?.key));
    } else {
      setEventInputList([]);
    }
  };

  return (
    <>
      <Modal
        className="int-popup-model"
        title={
          step === 1
            ? `Connect with ${appConnectPopupData?.popupData?.name}`
            : 'Select events'
        }
        visible={appConnectPopupData?.isPopupOpen}
        onCancel={handleClose}
        okText={step === 1 ? 'Save & Next' : 'Finish'}
        footer={false}
      >
        <div className="int-popup-content">
          {step === 1 ? (
            <>
              <div className="title">
                <p className="mb-0">
                  Follow these steps to connect your channel with your team:
                </p>
              </div>
              <div className="points">
                <ul>
                  <li>From your channel menu, open Connectors.</li>
                  <li>Search for Incoming Webhook and click Configure.</li>
                  <li>Name of the Webhook: AppsOnAir</li>
                  <li>Image:</li>
                  <li>Click on Create</li>
                  <li>And finally copy the URL</li>
                </ul>
              </div>
              <div className="inputs">
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleIntegrationForm}
                >
                  {appConnectPopupData?.popupData?.config?.length > 0 &&
                    appConnectPopupData?.popupData?.config?.map((item) => (
                      <>
                        {item?.inputType === 'string' ? (
                          <>
                            <Form.Item
                              name={item?.key}
                              key={item?.key}
                              className="input-label"
                              label={item?.label}
                              rules={[
                                {
                                  required:
                                    // eslint-disable-next-line no-unneeded-ternary
                                    item?.required === 'true' ? true : false,
                                  message: `${item?.label} is Required`
                                },
                                {
                                  pattern: item?.regex?.replace(/^\/|\/$/g, ''),
                                  message: `Please enter valid ${item?.label}`
                                }
                              ]}
                            >
                              <Input placeholder="https:/yourwebsiteurl.com/webhook" />
                            </Form.Item>
                          </>
                        ) : (
                          <>
                            <Form.Item
                              name={item?.key}
                              key={item?.key}
                              label={item?.label}
                              rules={[
                                {
                                  required:
                                    // eslint-disable-next-line no-unneeded-ternary
                                    item?.required === 'true' ? true : false,
                                  message: `${item?.label} is Required`
                                }
                              ]}
                            >
                              <Select placeholder="Please select type">
                                {item?.options?.map((option) => (
                                  <Option key={option?.key} value={option?.key}>
                                    {option?.value}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </>
                        )}
                      </>
                    ))}
                </Form>
              </div>
            </>
          ) : (
            <>
              <div className="event-container">
                <Checkbox
                  className="select-all"
                  onChange={handleSelectAll}
                  name="event"
                  checked={eventList?.length === eventInputList?.length}
                >
                  Select all
                </Checkbox>
                {eventList?.length > 0 &&
                  eventList?.map((item) => (
                    <div key={item?.key} className="event-input">
                      <Checkbox
                        onChange={(e) =>
                          handleEventsChange(e?.target?.checked, item?.key)
                        }
                        name="event"
                        checked={eventInputList?.includes(item?.key)}
                      >
                        {item?.name}
                      </Checkbox>
                    </div>
                  ))}
              </div>
            </>
          )}
          <div className="integration-popup-footer">
            <Button onClick={handleClose}>Cancel</Button>
            {step === 2 &&
              appConnectPopupData?.popupData?.action === 'POPUP' && (
                <Button onClick={() => setStep(1)}>Previous</Button>
              )}
            <Button
              disabled={isButtonLoader}
              type="primary"
              onClick={handleCommonSave}
            >
              {step === 1 ? 'Save & Next' : 'Save'}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AppConnectPopup;
