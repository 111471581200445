import {
  AndroidFilled,
  AppleFilled,
  InfoCircleOutlined
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Popconfirm,
  Row,
  Switch,
  Tooltip
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DEVELOPER_GUIDELINES_URL } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import { UPGRADE_APPLICATION } from './graphql/Mutations';
import './updateapp.less';

const { Panel } = Collapse;

const UpdateApplication = ({
  androidCollapse,
  setAndroidCollapse,
  iosCollapse,
  setIosCollapse,
  androidChecked,
  setAndroidChecked,
  iosChecked,
  setIosChecked,
  androidInitialValues,
  iosInitialValues,
  fetchUpgradeDetails
}) => {
  const appID = useParams();
  const id = appID?.appId;
  const {
    required,
    urlPlayStoreAppsOnAir,
    urlAppStoreAppsOnAir
  } = formValidatorRules;
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const androidRef = useRef(androidCollapse);
  const iosRef = useRef(iosCollapse);
  const [initialValues, setInitialValues] = useState({
    initAndroidCollapse: androidCollapse,
    initIosCollapse: iosCollapse
  });
  useEffect(() => {
    androidRef.current = androidCollapse;
  }, [androidCollapse]);

  useEffect(() => {
    iosRef.current = iosCollapse;
  }, [iosCollapse]);

  useEffect(() => {
    form?.setFieldsValue({
      androidUpdateLink: androidInitialValues?.androidUpdateLink,
      iosUpdateLink: iosInitialValues?.iosUpdateLink
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [androidInitialValues, iosInitialValues]);

  const [upgradeApplication] = useMutation(UPGRADE_APPLICATION, {
    onCompleted: () => {
      setInitialValues({
        initAndroidCollapse: androidCollapse,
        initIosCollapse: iosCollapse
      });
      fetchUpgradeDetails();
    },
    onError() {
      setBtnLoading(false);
    }
  });

  const handleUpgrade = (values) => {
    setBtnLoading(true);
    upgradeApplication({
      variables: {
        data: {
          ...values,
          appId: id,
          isIosUpdate: iosRef?.current,
          isAndroidUpdate: androidRef?.current,
          isIosForcedUpdate: iosChecked,
          isAndroidForcedUpdate: androidChecked
        }
      }
    });
    setBtnLoading(false);
  };

  return (
    <div>
      <Row>
        <div className="s-content font-12">
          To use this feature, you have to integrate our app SDK. View{' '}
          <u>
            <a
              href={DEVELOPER_GUIDELINES_URL}
              target="_blank"
              rel="noreferrer"
              className="link font-500"
            >
              Developer guidelines
            </a>
          </u>{' '}
          to know more.
        </div>
      </Row>
      <Row>
        <div className="heading font-16 mt-16 mb-4">Enable Updates</div>
      </Row>
      <Row>
        <InfoCircleOutlined className="info-icon" /> &nbsp;
        <Col className="static-content font-12">
          By enabling Updates, users will be able to see any new releases
          published in this app.
        </Col>
      </Row>
      <Form.Provider
        onFormFinish={(name, { values }) => {
          handleUpgrade(values);
        }}
      >
        <Row className="update-card-content">
          <Col span={24}>
            <Collapse
              activeKey={androidCollapse && 1}
              collapsible="header"
              expandIcon={null}
              ghost
              bordered
              expandIconPosition="right"
            >
              <Panel
                forceRender
                header={
                  <>
                    <AndroidFilled className="icon-style mr-8" />
                    <span className="content">Android</span>
                  </>
                }
                extra={
                  androidCollapse === true &&
                  initialValues?.initAndroidCollapse === true ? (
                    <Popconfirm
                      title="This will disable the functionality, Are you sure?"
                      okText="Yes"
                      cancelText="No"
                      overlayClassName="custom-popconfirm"
                      getPopupContainer={(trigger) => trigger?.parentNode}
                      onConfirm={() => {
                        setAndroidCollapse(false);
                        setAndroidChecked(false);
                        form?.submit();
                      }}
                    >
                      <Switch checked={androidCollapse} size="small" />
                    </Popconfirm>
                  ) : (
                    <Switch
                      onChange={(e) => {
                        setAndroidCollapse(e);
                        setAndroidChecked(false);
                      }}
                      checked={androidCollapse}
                      size="small"
                    />
                  )
                }
                key="1"
                showArrow={false}
              >
                {androidCollapse && (
                  <Form
                    name="android-collapse"
                    initialValues={androidInitialValues}
                    layout="vertical"
                    autoComplete="off"
                    form={form}
                  >
                    <Form.Item name="isAndroidForcedUpdate">
                      <Checkbox
                        checked={androidChecked}
                        onChange={() =>
                          setAndroidChecked(androidChecked !== true)
                        }
                      >
                        <span className="force-update-text">Force Update</span>
                        &nbsp;
                        <Tooltip title="By enabling, users have to install latest release in order to use the app.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Checkbox>
                    </Form.Item>
                    <Row>
                      <Col className="col-link">
                        <Form.Item
                          name="androidBuildNumber"
                          placeholder="Build Number"
                          label="Build Number"
                          rules={[
                            {
                              ...required,
                              message: 'Please enter build number'
                            }
                          ]}
                          normalize={(value, prevValue) => {
                            if (!value.match(/^[0-9]+$/)) {
                              return prevValue;
                              // eslint-disable-next-line no-else-return
                            } else {
                              return value;
                            }
                          }}
                        >
                          <Input placeholder="Build Number" type="text" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-link">
                        <Form.Item
                          label="Play store Link / AppsOnAir Link"
                          name="androidUpdateLink"
                          rules={[
                            {
                              required,
                              message: 'Enter URL'
                            },
                            urlPlayStoreAppsOnAir
                          ]}
                        >
                          <Input placeholder="Play store Link / AppsOnAir Link" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Panel>
            </Collapse>
          </Col>
        </Row>
        <Row className="update-card-content">
          <Col span={24}>
            <Collapse
              ghost
              activeKey={iosCollapse && 2}
              collapsible="header"
              expandIcon={null}
              bordered
              expandIconPosition="right"
            >
              <Panel
                forceRender
                header={
                  <>
                    <AppleFilled className="icon-style mr-8" />
                    <span className="content">iOS</span>
                  </>
                }
                extra={
                  iosCollapse === true && initialValues?.initIosCollapse ? (
                    <Popconfirm
                      overlayClassName="custom-popconfirm"
                      title="This will disable the functionality, Are you sure?"
                      okText="Yes"
                      cancelText="No"
                      getPopupContainer={(trigger) => trigger?.parentNode}
                      onConfirm={() => {
                        setIosCollapse(false);
                        setIosChecked(false);
                        form?.submit();
                      }}
                    >
                      <Switch size="small" checked={iosCollapse} />
                    </Popconfirm>
                  ) : (
                    <Switch
                      onChange={(e) => {
                        setIosCollapse(e);
                        setIosChecked(false);
                      }}
                      size="small"
                      checked={iosCollapse}
                    />
                  )
                }
                key="2"
                showArrow={false}
              >
                {iosCollapse && (
                  <Form
                    name="ios"
                    initialValues={iosInitialValues}
                    layout="vertical"
                    autoComplete="off"
                    form={form}
                  >
                    <Form.Item name="isIosForcedUpdate">
                      <Checkbox
                        checked={iosChecked}
                        onChange={() => setIosChecked(iosChecked !== true)}
                      >
                        <span className="force-update-text">Force Update</span>
                        &nbsp;
                        <Tooltip title="By enabling, users have to install latest release in order to use the app.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Checkbox>
                    </Form.Item>
                    <Row className="row-build">
                      <Col className="col-build">
                        <Form.Item
                          name="iosMinBuildVersion"
                          label="Version Number"
                          rules={[
                            {
                              ...required,
                              message: 'Please enter version number'
                            }
                          ]}
                          normalize={(value, prevValue) => {
                            if (!value.match(/^[0-9.]+$/)) {
                              return prevValue;
                              // eslint-disable-next-line no-else-return
                            } else {
                              return value;
                            }
                          }}
                        >
                          <Input placeholder="Build version" type="text" />
                        </Form.Item>
                      </Col>
                      <Col className="col-build">
                        <Form.Item
                          name="iosBuildNumber"
                          placeholder="Build Number"
                          label="Build Number"
                          rules={[
                            {
                              ...required,
                              message: 'Please enter build number'
                            }
                          ]}
                          normalize={(value, prevValue) => {
                            if (!value.match(/^[0-9]+$/)) {
                              return prevValue;
                              // eslint-disable-next-line no-else-return
                            } else {
                              return value;
                            }
                          }}
                        >
                          <Input placeholder="Build Number" type="text" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-link">
                        <Form.Item
                          label="Apple app store Link / AppsOnAir Link"
                          name="iosUpdateLink"
                          rules={[
                            { required, message: 'Enter URL' },
                            urlAppStoreAppsOnAir
                          ]}
                        >
                          <Input placeholder="Apple app store Link / AppsOnAir Link" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Panel>
            </Collapse>
          </Col>
        </Row>
        {(androidCollapse || iosCollapse) && (
          <Row className="update-button">
            <Form.Item>
              <Button
                onClick={form?.submit}
                type="primary"
                htmlType="submit"
                loading={btnLoading}
                className="save-btn"
              >
                Save
              </Button>
            </Form.Item>
          </Row>
        )}
      </Form.Provider>
    </div>
  );
};

export default UpdateApplication;
