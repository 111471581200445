import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Avatar, Button, Divider, Dropdown, Layout, Menu } from 'antd';
import { filter, findIndex } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import blankIcon from '../../../assets/images/blank-icon.png';
import Logo from '../../../assets/images/logo-big.png';
import downArrow from '../../../assets/svg/down-arrow.svg';
import settingsIcon from '../../../assets/svg/settings-icon.svg';
import {
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  TEAM_KEY
} from '../../../common/constants';
import { googleAnalyticsEvents } from '../../../common/utils';
import { GET_USER_TEAM } from '../../../modules/auth/graphql/Queries';
import UserProfile from './UserProfile';
import './appHeader.less';

const { Header } = Layout;

const AppHeader = () => {
  const location = useLocation();
  const setLocation = location?.pathname?.split('/');
  const history = useHistory();
  const {
    dispatch,
    state: { currentUser, teamId, userTeams, fetchUserTeams, createNewTeam }
  } = useContext(AppContext);
  const [selectedKey, setSelectedKey] = useState(teamId || null);

  const [getUserTeams, { data }] = useLazyQuery(GET_USER_TEAM, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      let value = '';
      // eslint-disable-next-line no-undef
      const selectedTeamId = localStorage?.getItem(TEAM_KEY);
      if (selectedTeamId) {
        dispatch({
          type: 'SET_TEAM',
          data: selectedTeamId
        });
        value = filter(res?.getUserTeams, (val) => val?.id === selectedTeamId);
      } else {
        dispatch({
          type: 'SET_TEAM',
          data: res?.getUserTeams?.[0]?.id
        });
        value = res?.getUserTeams?.[0];
      }
      dispatch({
        type: 'SET_USER_TEAMS',
        data: res?.getUserTeams
      });
      dispatch({
        type: 'SET_CURRENT_ROLE',
        data: value
      });
      if (createNewTeam) {
        setSelectedKey(res?.getUserTeams?.[0]?.id);
        dispatch({
          type: 'SET_NEW_TEAM',
          data: false
        });
      }
      if (!selectedKey) {
        setSelectedKey(res?.getUserTeams?.[0]?.id);
      }
    }
  });

  useEffect(() => {
    if (createNewTeam || fetchUserTeams) {
      getUserTeams();
      dispatch({
        type: 'SET_FETCH_USER_TEAMS',
        data: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNewTeam, fetchUserTeams]);

  useEffect(() => {
    getUserTeams();
    dispatch({
      type: 'SET_FETCH_USER_TEAMS',
      data: false
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenuClick = (team) => {
    googleAnalyticsEvents(GA_EVENT?.CHANGE_WORKSPACE, {
      label: GA_LABEL?.CHANGE_WORKSPACE,
      workspace_id: team?.id,
      member_id: currentUser?.id
    });
    setSelectedKey(team?.id);
    const value = filter(userTeams, (val) => val?.id === team?.id);
    dispatch({
      type: 'SET_CURRENT_ROLE',
      data: value
    });
    dispatch({
      type: 'SET_TEAM',
      data: team?.id
    });
    if (location?.pathname !== ROUTES?.APPS) {
      history?.push(ROUTES?.APPS);
    }
  };

  const handleChange = (id) => {
    history?.push(`${ROUTES?.WORKSPACE_SETTINGS}/${id}`);
  };

  const handleMenuChange = (key) => {
    switch (key?.key) {
      case 'apps':
        history?.push(ROUTES?.APPS);
        break;
      case 'teams':
        history?.push(ROUTES?.TEAMS);
        break;
      case 'integrations':
        history?.push(ROUTES?.INTEGRATIONS);
        break;
      default:
        history?.push(ROUTES?.APPS);
    }
  };

  const selectedIndex = findIndex(
    data?.getUserTeams,
    (item) => item?.id === teamId
  );

  const workspaceLogo =
    data?.getUserTeams?.[selectedIndex >= 0 ? selectedIndex : '']?.teamLogo ||
    null;

  const menu = (
    <div className="workspace-dropdown">
      <Menu className="header-menu" selectedKeys={selectedKey}>
        {data?.getUserTeams?.map((team) => (
          <Menu.Item key={team?.id} onClick={() => handleMenuClick(team)}>
            <div className="d-flex align-center">
              <div className="team-name-col">
                <Avatar
                  src={team?.teamLogo || blankIcon}
                  alt="teamLogo"
                  shape="square"
                  className="team-avatar mr-4"
                  size={18}
                />
                <span className="team-heading font-14" title={team?.teamName}>
                  {team?.teamName}
                </span>
              </div>
              <div>
                <img
                  src={settingsIcon}
                  alt="icon"
                  width={18}
                  onClick={(e) => {
                    e?.stopPropagation();
                    handleChange(team?.id);
                  }}
                />
              </div>
            </div>
          </Menu.Item>
        ))}
      </Menu>
      <div className="workspace-title sticky-button">
        <Divider />
        <Link
          onClick={() => {
            googleAnalyticsEvents(GA_EVENT?.CREATE_NEW_WORKSPACE, {
              label: GA_LABEL?.CREATE_NEW_WORKSPACE,
              member_id: currentUser?.id
            });
            dispatch({ type: 'SET_CURRENT_VALUE', data: 0 });
          }}
          to={ROUTES?.ONBOARD}
        >
          <div className="create-workspace-content">
            <PlusOutlined className="mr-8" />
            <span className="text">Create Workspace</span>
          </div>
        </Link>
      </div>
    </div>
  );

  return (
    <Header className="app-header">
      <div className="header-left">
        <img
          alt="apps-on-air"
          src={Logo}
          className="logo mr-8"
          onClick={
            userTeams?.length > 0 ? () => history?.push(ROUTES?.APPS) : () => {}
          }
        />
        {!location?.pathname?.includes(ROUTES?.ONBOARD) && (
          <>
            <Divider type="vertical" className="app-header-divider" />
            <div className="header-left-content">
              <Menu
                mode="horizontal"
                selectedKeys={setLocation}
                onClick={handleMenuChange}
              >
                <Menu.Item key="apps">Apps</Menu.Item>
                <Menu.Item key="teams">Team</Menu.Item>
                {/* Hide the tab for PROD */}
                {/* <Menu.Item key="integrations">Integrations</Menu.Item> */}
              </Menu>
            </div>
          </>
        )}
      </div>
      <div className="header-right">
        {!location?.pathname?.includes(ROUTES?.ONBOARD) && (
          <>
            <Dropdown
              overlayClassName="dropdown-header"
              overlay={menu}
              trigger={['click']}
              placement="topRight"
            >
              <Button type="text" className="workspace-dropdown">
                <img
                  className="team-logo"
                  alt="logo"
                  width={18}
                  src={workspaceLogo || blankIcon}
                />
                <span
                  className="font-600 ml-8 mr-12"
                  title={
                    data?.getUserTeams?.[selectedIndex >= 0 ? selectedIndex : 0]
                      ?.teamName
                  }
                >
                  {
                    data?.getUserTeams?.[selectedIndex >= 0 ? selectedIndex : 0]
                      ?.teamName
                  }
                </span>
                <img src={downArrow} alt="down-arrow" className="down-arrow" />
              </Button>
            </Dropdown>
            <Divider className="header-divider" type="vertical" />
            <div className="header-notification">
              <UserProfile />
            </div>
          </>
        )}
      </div>
    </Header>
  );
};
export default AppHeader;
