import { useLazyQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppNameHeader from '../../components/AppNameHeader';
import LoaderComponent from '../../components/LoaderComponent';
import AppServices from './AppServices';
import MaintenanceApplication from './MaintenanceApplication';
import MaintenancePreview from './MaintenancePreview';
import { GET_APP_MAINTENANCE_DETAIL } from './graphql/Queries';
import './maintenanceservices.less';

const MaintenanceServices = () => {
  const appID = useParams();
  const id = appID?.appId;

  const [maintenanceCollapse, setMaintenanceCollapse] = useState(null);
  const [maintenanceConfig, setMaintenanceConfig] = useState({});
  const [previewImage, setPreviewImage] = useState();

  const [initialValues, setInitialValues] = useState(null);
  const [loading, setLoading] = useState(true);

  const [fetchData] = useLazyQuery(GET_APP_MAINTENANCE_DETAIL, {
    fetchPolicy: 'network-only',
    variables: {
      appId: id
    },
    onCompleted(res) {
      if (res?.getAppMaintenanceDetail?.isMaintenance) {
        setMaintenanceCollapse(1);
        setInitialValues({
          maintenanceTitle: res?.getAppMaintenanceDetail?.maintenanceTitle,
          maintenanceDescription:
            res?.getAppMaintenanceDetail?.maintenanceDescription,
          maintenanceImage: res?.getAppMaintenanceDetail?.maintenanceImage,
          textColorCode: res?.getAppMaintenanceDetail?.textColorCode,
          backgroundColorCode: res?.getAppMaintenanceDetail?.backgroundColorCode
        });
        setMaintenanceConfig({
          maintenanceTitle: res?.getAppMaintenanceDetail?.maintenanceTitle,
          maintenanceDescription:
            res?.getAppMaintenanceDetail?.maintenanceDescription,
          maintenanceImage: res?.getAppMaintenanceDetail?.maintenanceImage,
          textColorCode: res?.getAppMaintenanceDetail?.textColorCode,
          backgroundColorCode: res?.getAppMaintenanceDetail?.backgroundColorCode
        });
      }
      setLoading(false);
    },
    onError() {
      setLoading(false);
    }
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppNameHeader />
      <AppServices>
        {loading ? (
          <LoaderComponent spinning setHeight="200" />
        ) : (
          <Row wrap={false}>
            <Col flex="auto" className="maintenance-content-row">
              <MaintenanceApplication
                maintenanceCollapse={maintenanceCollapse}
                setMaintenanceCollapse={setMaintenanceCollapse}
                setMaintenanceConfig={setMaintenanceConfig}
                previewImage={previewImage}
                setPreviewImage={setPreviewImage}
                initialValues={initialValues}
                fetchData={fetchData}
              />
            </Col>
            <Col>
              <div className="maintenance-content-preview">
                <MaintenancePreview
                  maintenanceCollapse={maintenanceCollapse}
                  maintenanceConfig={maintenanceConfig}
                  previewImage={previewImage}
                  maintenanceImage={initialValues?.maintenanceImage}
                />
              </div>
            </Col>
          </Row>
        )}
      </AppServices>
    </>
  );
};

export default MaintenanceServices;
