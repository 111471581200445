import { Row } from 'antd';
import React from 'react';
import maintenanceIcon from '../../assets/images/maintenance-icon.png';
import './maintenancepreview.less';

const MaintenancePreview = ({
  maintenanceConfig,
  maintenanceCollapse,
  previewImage,
  maintenanceImage
}) => {
  const configIsEmpty = Object?.values(maintenanceConfig)?.every((value) => {
    if (value === '' || !value) {
      return true;
    }
    return false;
  });

  return (
    <>
      <style>
        {`.text-description{
              color:${maintenanceConfig?.textColorCode}
            }
            .theme-changer {
              background-color: ${maintenanceConfig?.backgroundColorCode}
            }
        `}
      </style>
      <div className="maintenance-preview-mobile" />
      {maintenanceCollapse === 1 ? (
        <>
          {configIsEmpty && !previewImage && (
            <div>
              <div>
                <img
                  src={maintenanceIcon}
                  alt="icon"
                  width={55}
                  className="main-image-preview"
                />
              </div>
              <span className="image-preview-text font-12">
                This app is under maintenance
              </span>
            </div>
          )}
          {!configIsEmpty && (
            <div className="maintenance-preview theme-changer">
              <div className="maintenance-preview-content">
                <Row>
                  {previewImage !== null && (
                    <img
                      src={previewImage || maintenanceImage || maintenanceIcon}
                      alt="appIcon"
                      width={48}
                      className="image-preview"
                    />
                  )}
                </Row>
                <Row>
                  <span className="text-description maintenance-text font-12">
                    {maintenanceConfig?.maintenanceTitle}
                  </span>
                </Row>
                <Row>
                  <span className="text-description main-text font-10">
                    {maintenanceConfig?.maintenanceDescription}
                  </span>
                </Row>
              </div>
            </div>
          )}
        </>
      ) : (
        <span className="preview-text font-14">Preview will appear here</span>
      )}
    </>
  );
};

export default MaintenancePreview;
