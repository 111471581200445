import { gql } from '@apollo/client';

export const CREATE_INTEGRATION_CONFIG = gql`
  mutation createIntegrationConfig($data: CreateIntegrationConfigInput!) {
    createIntegrationConfig(data: $data) {
      data {
        id
        integrationId
        integrationKey
        teamId
        configs
      }
      message
    }
  }
`;

export const CREATE_INTEGRATION_EVENT = gql`
  mutation CreateIntegrationEvent($data: CreateIntegrationEventInput!) {
    createIntegrationEvent(data: $data) {
      message
    }
  }
`;

export const UPDATE_INTEGRATION = gql`
  mutation UpdateIntegrationConfig(
    $data: UpdateIntegrationConfigInput!
    $where: IntegrationConfigUniqueInput!
  ) {
    updateIntegrationConfig(data: $data, where: $where) {
      data {
        id
        integrationId
        integrationKey
      }
      message
    }
  }
`;

export const DELETE_INTEGRATION = gql`
  mutation DeleteIntegrationConfig($where: IntegrationConfigUniqueInput!) {
    deleteIntegrationConfig(where: $where) {
      message
    }
  }
`;

export const VERIFY_INTEGRATION_AUTH_CODE = gql`
  mutation VerifyIntegrationAuthCode(
    $platform: IntegrationPlatform!
    $authorizationCode: String!
    $teamId: ID!
  ) {
    verifyIntegrationAuthCode(
      platform: $platform
      authorizationCode: $authorizationCode
      teamId: $teamId
    ) {
      message
    }
  }
`;
