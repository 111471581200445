import { SettingOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Card, Popconfirm, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  useHistory,
  useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import { ACTIONS, ROUTES, TEAM_KEY } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import LoaderComponent from '../../components/LoaderComponent';
import AppConnectPopup from './components/AppConnectPopup';
import {
  DELETE_INTEGRATION,
  VERIFY_INTEGRATION_AUTH_CODE
} from './graphql/Mutations';
import {
  GET_INTEGRATIONS_LIST,
  GET_INTEGRATION_AUTH_URL
} from './graphql/Queries';
import './style/integration.less';

const { Title } = Typography;
function Integrations() {
  const history = useHistory();
  const { search } = useLocation();
  const searchUrl = new URLSearchParams(search);
  const platformQuery = searchUrl?.get('platform');
  const codeQueryParameter = searchUrl?.get('code');
  // eslint-disable-next-line no-undef
  const teamId = localStorage?.getItem(TEAM_KEY);
  const [loader, setLoader] = useState(true);
  const [integrationList, setIntegrationList] = useState([]);
  const [appConnectPopup, setAppConnectPopup] = useState({
    isPopupOpen: false,
    isEdit: false,
    popupData: {}
  });
  const [selectedDeletePopupId, setSelectedDeletePopupId] = useState(null);

  const [getIntegrationList] = useLazyQuery(GET_INTEGRATIONS_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      where: { teamId }
    },
    onCompleted: (res) => {
      setLoader(false);
      setIntegrationList(res?.getConfiguredIntegrations?.data);
      if (codeQueryParameter && platformQuery) {
        const integrationListData = res?.getConfiguredIntegrations?.data;
        setAppConnectPopup({
          isPopupOpen: true,
          isEdit: true,
          popupData:
            integrationListData[
              integrationListData?.findIndex(
                (item) => item?.key === platformQuery
              )
            ]
        });
        history?.replace(ROUTES?.INTEGRATIONS);
      }
    },
    onError() {
      setLoader(false);
    }
  });

  const [deleteIntegration] = useMutation(DELETE_INTEGRATION, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setSelectedDeletePopupId(null);
      getIntegrationList();
    },
    onError() {
      setLoader(false);
    }
  });

  const [getIntegrationAuthUrl] = useLazyQuery(GET_INTEGRATION_AUTH_URL, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      // eslint-disable-next-line no-undef
      window.open(res?.getIntegrationAuthUrl?.url, '_blank');
      setLoader(false);
    },
    onError() {
      setLoader(false);
    }
  });

  const [verifyAuthUrl] = useMutation(VERIFY_INTEGRATION_AUTH_CODE, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      getIntegrationList();
      setLoader(false);
    },
    onError() {
      /* if verification failed remove all query parameter */
      history?.replace(ROUTES?.INTEGRATIONS);
      setLoader(false);
    }
  });

  useEffect(() => {
    if (codeQueryParameter && platformQuery) {
      verifyAuthUrl({
        variables: {
          platform: platformQuery,
          authorizationCode: codeQueryParameter,
          teamId: teamId
        }
      });
    } else {
      getIntegrationList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIntegration = (event, integrationData) => {
    if (event) {
      if (integrationData?.action === 'POPUP') {
        setAppConnectPopup({
          isPopupOpen: event,
          popupData: integrationData,
          isEdit: false
        });
      } else {
        /* handling redirection based integration services */
        getIntegrationAuthUrl({
          variables: {
            platform: integrationData?.key,
            teamId: teamId
          }
        });
      }
    } else {
      setSelectedDeletePopupId(integrationData?.id);
    }
  };

  const handleDeletePopConfirm = (index) => {
    const configId = integrationList?.[index].integrationConfigs?.[0].id;
    deleteIntegration({
      variables: {
        where: {
          integrationConfigId: configId
        }
      }
    });
  };

  return (
    <>
      <div className="integration">
        <Title className="title">Integrations</Title>
        <p className="description">Connect AppsOnAir to your favorite apps</p>
        {loader ? (
          <LoaderComponent setHeight="500" />
        ) : (
          <div className="integration-container">
            {integrationList?.map((item, index) => (
              <Card key={item?.name} className="services-box">
                <div className="service-info">
                  <div>
                    <img
                      className="service-img"
                      src={item?.logo}
                      alt={item?.name}
                    />
                  </div>
                  <div>
                    {AccessControl({
                      allowedPermissions: ACTIONS?.INTEGRATION_SETTINGS
                    }) &&
                      item?.isConfigured && (
                        <SettingOutlined
                          onClick={() =>
                            setAppConnectPopup({
                              isPopupOpen: true,
                              popupData: item,
                              isEdit: true
                            })
                          }
                          className="setting-icon"
                        />
                      )}
                    <Popconfirm
                      title="This will remove integration, Are you sure?"
                      okText="Yes"
                      cancelText="No"
                      overlayClassName="custom-popconfirm"
                      getPopupContainer={(trigger) => trigger?.parentNode}
                      visible={Boolean(selectedDeletePopupId === item?.id)}
                      onConfirm={() => {
                        handleDeletePopConfirm(index);
                      }}
                      onCancel={() => setSelectedDeletePopupId(item?.id)}
                    >
                      <Switch
                        checked={item?.isConfigured}
                        onChange={(e) => handleIntegration(e, item)}
                        disabled={
                          !AccessControl({
                            allowedPermissions: ACTIONS?.INTEGRATION_SETTINGS
                          })
                        }
                      />
                    </Popconfirm>
                  </div>
                </div>
                <Title className="inner-title">{item?.name}</Title>
                <p className="inner-desc">{item?.description}</p>
              </Card>
            ))}
          </div>
        )}
        <AppConnectPopup
          appConnectPopupData={appConnectPopup}
          handleClose={() =>
            setAppConnectPopup({
              isPopupOpen: false,
              popupData: {},
              isEdit: false
            })
          }
          teamId={teamId}
          getIntegrationList={getIntegrationList}
        />
      </div>
    </>
  );
}

export default Integrations;
